function openPopup() {
  const event = new Event('click');
  const target = document.querySelector('.popup-new-link');
  const isPopupSee = sessionStorage.getItem('isPopupSee');
  const isModalLeaved = sessionStorage.getItem('isModalLeaved');
  if (target && !document.querySelector('body').classList.contains('modal-opened') && !isPopupSee && isModalLeaved) {
    target.dispatchEvent(event);
    sessionStorage.setItem('isPopupSee', 'isPopupSee');
  }
}

function mouseLeave() {
  document.addEventListener('mouseleave', openPopup);
}

function modalChecker() {
  const closer = document.querySelector('.modal__closer');
  const isModalLeaved = sessionStorage.getItem('isModalLeaved');
  if (closer && !closer.dataset.binded && !isModalLeaved) {
    closer.addEventListener('click', () => {
      sessionStorage.setItem('isModalLeaved', 'isModalLeaved');
    });
    closer.dataset.binded = 'true';
  }
}

document.addEventListener('DOMContentLoaded', mouseLeave);
document.addEventListener('DOMContentMutated', modalChecker);