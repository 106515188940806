const header = document.querySelector('[data-header]');
let scrolled = false;

function toggler() {
  if (window.scrollY > 30) {
    if (!scrolled) {
      header.classList.add('_scrolled');
      scrolled = true;
    }
  } else if (scrolled) {
    header.classList.remove('_scrolled');
    scrolled = false;
  }
}
window.addEventListener('scroll', toggler);